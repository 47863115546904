@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap&subset=cyrillic");
.header {
  color: #fff;
  background: -webkit-gradient(linear, left top, left bottom, from(#06f), to(#00038d));
  background: linear-gradient(to bottom, #06f 0%, #00038d 100%); }

div.header__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px; }

.header__logo {
  margin-right: 10px; }

.header__link {
  display: block; }

.header__dates {
  counter-reset: myCounter;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.header__date {
  position: relative;
  padding: 0 40px; }
  .header__date::before {
    counter-increment: myCounter;
    content: counter(myCounter);
    position: absolute;
    left: 0;
    top: 50%;
    width: 37px;
    height: 37px;
    font-weight: 600;
    font-size: 16px;
    line-height: 37px;
    text-align: center;
    background: url("../../img/star.svg") center no-repeat;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .header__date p {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #fff; }
    .header__date p:first-child {
      font-size: 12px;
      line-height: 15px;
      color: #bbc1ff; }
  .header__date:last-child p:last-child {
    font-size: 14px; }

@media (max-width: 980px) {
  div.header__wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .header__dates {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    margin-top: 20px; } }

@media (max-width: 880px) {
  div.main-wrapper {
    background: none; }
    div.main-wrapper .header {
      background: #00038d; } }

@media (max-width: 768px) {
  .header__dates {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .header__date {
    -ms-flex-preferred-size: 49%;
        flex-basis: 49%;
    margin: 10px 0; } }

div.registration {
  margin-bottom: 0; }
  div.registration ul.registration__menu {
    top: 44px !important;
    right: 0 !important;
    left: auto !important; }
    div.registration ul.registration__menu .mi_header__name {
      color: #131313; }

.registration__link, .registration__cabinet {
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #131313;
  padding: 12px 20px;
  cursor: pointer;
  background-color: #fecb2f;
  border-radius: 100px; }
  .registration__link:hover, .registration__cabinet:hover {
    background-color: #f9bc01; }

.information {
  margin-top: 78px; }

.information__wrap {
  position: relative;
  z-index: 1; }
  .information__wrap::after {
    content: "";
    position: absolute;
    left: -50px;
    bottom: 0;
    z-index: -1;
    width: 1250px;
    height: 400px;
    background: url("../../img/circle-2.svg") center top no-repeat; }

.information__title {
  padding-top: 50px;
  padding-bottom: 15px;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: url("../../img/line.svg") center bottom no-repeat; }

.information__img {
  display: block;
  margin: 10px auto;
  height: auto;
  max-width: 620px; }

.information__cert {
  display: block;
  margin: 0 auto; }

.information__name {
  position: relative;
  margin: 0 auto;
  max-width: 750px; }
  .information__name::after, .information__name::before {
    content: "";
    position: absolute;
    left: 0;
    top: 4%;
    width: 43px;
    height: 43px;
    background: url("../../img/star-icon.svg") left top no-repeat;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .information__name::before {
    left: auto;
    right: 0; }

.information__certificate {
  margin: 20px 0;
  text-align: center; }

.information__price,
.information__for-whom {
  position: absolute;
  z-index: 1;
  border-radius: 20px;
  padding: 15px;
  width: 172px;
  background-color: #0017b2; }
  .information__price::after,
  .information__for-whom::after {
    content: "";
    position: absolute;
    left: 20px;
    bottom: 100%;
    z-index: -1;
    width: 112px;
    height: 270px;
    background: url("../../img/gradient.svg") left top no-repeat; }

.information__price {
  left: 10px;
  bottom: 0;
  padding: 25px 20px;
  text-align: center; }
  .information__price p {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #fff; }
    .information__price p > span {
      display: block;
      margin-top: 8px;
      font-weight: 700;
      font-size: 55px;
      line-height: 64px;
      color: #fecb2f; }
      .information__price p > span span {
        margin-left: 10px;
        font-weight: 400; }

.information__for-whom {
  right: 10px;
  bottom: 0; }
  .information__for-whom p {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #fff; }
    .information__for-whom p span {
      color: #fecb2f; }

@media (max-width: 1240px) {
  .information__wrap::after {
    left: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 1240px;
    background-size: cover; } }

@media (max-width: 880px) {
  .information {
    margin-top: 0;
    padding-bottom: 30px;
    background: -webkit-gradient(linear, left top, left bottom, from(#00038d), to(#0042ff));
    background: linear-gradient(to bottom, #00038d, #0042ff); }
  .information__title {
    padding-top: 25px; }
  .information__wrap::after {
    display: none; }
  .information__for-whom,
  .information__price {
    position: static; }
    .information__for-whom::after,
    .information__price::after {
      display: none; }
  .information__block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; } }

@media (max-width: 768px) {
  .information__name::after, .information__name::before {
    display: none; } }

@media (max-width: 400px) {
  .information__block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .information__price {
    text-align: center; }
  .information__for-whom,
  .information__price {
    margin: 10px 0;
    width: auto; } }

.form-order {
  position: relative;
  z-index: 1;
  margin-top: -20px;
  border: none;
  padding: 0; }
  .form-order::before {
    content: "";
    position: absolute;
    right: -140px;
    top: -74px;
    z-index: -1;
    width: 200px;
    height: 208px;
    background: url("../../img/leaf-right.png") no-repeat; }
  .form-order::after {
    content: "";
    position: absolute;
    left: -113px;
    top: -84px;
    z-index: -1;
    width: 179px;
    height: 208px;
    background: url("../../img/leaf-left.png") no-repeat; }
  .form-order input:not([type="radio"]),
  .form-order input:not([type="checkbox"]),
  .form-order select {
    border-radius: 100px;
    height: 45px; }
  .form-order select {
    padding-right: 35px; }
  .form-order--close::before, .form-order--close::after {
    display: none; }
  .form-order--close .form-order__wrap {
    padding-top: 50px;
    padding-bottom: 50px; }

.form-order--no-leaf {
  margin-top: 0; }
  .form-order--no-leaf::after, .form-order--no-leaf::before {
    display: none; }

.form-order__wrap {
  border-radius: 20px;
  padding: 30px;
  -webkit-box-shadow: 0 -6px 20px rgba(0, 0, 0, 0.15);
          box-shadow: 0 -6px 20px rgba(0, 0, 0, 0.15);
  background: linear-gradient(357.81deg, #ffd260 5.58%, #ffe375 89.74%); }

.form-order__title {
  color: #c55431; }
  .form-order__title::after, .form-order__title::before {
    display: none; }

.form-order__login input:not([type="radio"]),
.form-order__login input:not([type="checkbox"]),
.form-order__login select {
  background: #fff; }

.form-order__login--inputs > div {
  margin-top: 10px;
  margin-bottom: 10px; }

.form-order__num_input:not([type="checkbox"]) {
  background: #fff; }

.form-order__v-calc-prices label {
  font-size: 18px;
  line-height: 22px;
  color: #9d3c1e; }

.form-order__login_name_wr_select::before {
  content: "";
  right: 15px;
  width: 16px;
  height: 16px;
  background: url("../../img/arrow-down.svg") no-repeat; }

.form-order__num_p button {
  width: 30px;
  height: 30px;
  background: url("../../img/plus.svg") center no-repeat; }

.key_order__num_m button {
  width: 30px;
  height: 30px;
  background: url("../../img/minus.svg") center no-repeat; }

.form-order__pay_summ {
  border: none;
  height: 45px;
  line-height: 45px;
  color: #131313;
  background: #fff; }
  .form-order__pay_summ::after, .form-order__pay_summ::before {
    display: none; }

.form-order__btn_wr {
  margin-top: 0;
  border: none;
  padding: 0;
  background: none; }
  .form-order__btn_wr button {
    position: relative;
    height: 45px;
    -webkit-box-shadow: 0 10px 20px #f48b22;
            box-shadow: 0 10px 20px #f48b22;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(-27.78%, #ff531d), color-stop(98.63%, #ff7121));
    background: linear-gradient(180deg, #ff531d -27.78%, #ff7121 98.63%); }
    .form-order__btn_wr button::before {
      content: "";
      position: absolute;
      right: 50px;
      top: -40px;
      width: 35px;
      height: 35px;
      background: url("../../img/orange-star.svg") center no-repeat;
      -webkit-transform: rotate(25deg);
              transform: rotate(25deg); }
    .form-order__btn_wr button::after {
      content: "";
      position: absolute;
      left: 50px;
      top: -40px;
      width: 35px;
      height: 35px;
      background: url("../../img/orange-star.svg") center no-repeat; }
    .form-order__btn_wr button:hover {
      background: -webkit-gradient(linear, left top, left bottom, color-stop(-27.78%, #e93800), color-stop(98.63%, #ed5500));
      background: linear-gradient(180deg, #e93800 -27.78%, #ed5500 98.63%); }

.form-order__msg {
  padding-top: 10px;
  background: none; }
  .form-order__msg p {
    font-size: 14px;
    line-height: 21px;
    color: #80660e; }
    .form-order__msg p:first-child {
      color: #131313; }
  .form-order__msg a {
    text-decoration: underline;
    color: #80660e; }
    .form-order__msg a:hover {
      text-decoration: none; }

@media (max-width: 1100px) {
  .form-order::after {
    display: none; }
  .form-order::before {
    display: none; } }

@media (max-width: 767px) {
  .form-order__wrap {
    padding: 30px 15px; }
  .form-order__msg {
    margin: 15px 0 0;
    padding: 0; }
    .form-order__msg p {
      font-size: 14px;
      line-height: 21px; } }

@media (max-width: 480px) {
  div.form-order__end p {
    width: 100%; } }

.about__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.about__left-wrap {
  -ms-flex-preferred-size: 364px;
      flex-basis: 364px;
  margin-right: 50px; }

.about__left {
  margin-bottom: 97px;
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
  background: linear-gradient(358.01deg, #06f 0.85%, #00038d 98.32%); }

.about__right {
  -ms-flex-preferred-size: calc(100% - 384px);
      flex-basis: calc(100% - 384px); }

.about__title {
  position: relative;
  display: inline-block;
  padding-right: 70px; }
  .about__title::after {
    content: "";
    position: absolute;
    right: 40px;
    top: 8px;
    width: 33px;
    height: 33px;
    background-color: #fecb2f;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .about__title span {
    display: block;
    -webkit-clip-path: polygon(100% 0, calc(100% - 25px) 50%, 100% 100%, 0 100%, 0 0);
            clip-path: polygon(100% 0, calc(100% - 25px) 50%, 100% 100%, 0 100%, 0 0);
    padding: 10px 0 10px 35px;
    width: 291px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #00048f;
    background-color: #fecb2f; }

.about__element {
  margin: 10px 35px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 63px; }
  .about__element p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #fff; }
  .about__element span {
    display: block;
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px; }
  .about__element:nth-of-type(1) {
    background: url("../../img/about-left/about-1.svg") left 0 top 11px no-repeat; }
  .about__element:nth-of-type(2) {
    background: url("../../img/about-left/about-2.svg") left 0 top 11px no-repeat; }
  .about__element:nth-of-type(3) {
    background: url("../../img/about-left/about-3.svg") left 0 top 11px no-repeat; }
  .about__element:nth-of-type(4) {
    background: url("../../img/about-left/about-4.svg") left 0 top 11px no-repeat; }
  .about__element:nth-of-type(5) {
    background: url("../../img/about-left/about-5.svg") left 0 top 11px no-repeat; }
  .about__element:nth-of-type(6) {
    background: url("../../img/about-left/about-6.svg") left 0 top 11px no-repeat; }
  .about__element:nth-of-type(7) {
    background: url("../../img/about-left/about-7.svg") left 0 top 11px no-repeat; }
  .about__element:nth-of-type(8) {
    background: url("../../img/about-left/about-8.svg") left 0 top 11px no-repeat; }
  .about__element:nth-of-type(9) {
    background: url("../../img/about-left/about-9.svg") left 0 top 11px no-repeat; }
  .about__element:nth-of-type(10) {
    background: url("../../img/about-left/about-10.svg") left 0 top 11px no-repeat; }

.about__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 15px; }

.about__subtitle {
  margin: 0 0 17px;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px; }

.about__text {
  max-width: 358px; }

.about__text p {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #000; }

.about__text span {
  font-weight: 700; }

.about__advantages {
  margin: 0 0 75px; }

.about__specification {
  margin-bottom: 58px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #000; }

.about__specification span {
  font-weight: 700; }

.about__specification p:not(:last-child) {
  margin-bottom: 32px; }

.about__relations {
  border-radius: 20px;
  background: linear-gradient(349.29deg, #ffd260 5.58%, #ffe375 89.74%); }

.about__relations-wrap {
  padding: 30px 20px 102px;
  background: url("../../img/relations-bg.svg") right 28px bottom 19px no-repeat; }

.about__relations__title {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #242424; }

.about__relations__descr {
  margin-bottom: 8px;
  max-width: 276px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #242424; }

.about__relations__link {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #242424; }

@media (max-width: 980px) {
  .about__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .about__left {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    margin-right: 0;
    margin-bottom: 20px; }
  .about__right {
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .about__element {
    padding-top: 10px;
    padding-bottom: 10px; }
  .information__img {
    width: 100%;
    height: 100%; }
  .about__left-wrap {
    margin-right: 0; }
  .about__relations {
    margin-bottom: 20px; }
  .about__relations-wrap {
    padding: 30px 20px; } }

@media (max-width: 600px) {
  .about__block img {
    max-width: 65%; }
  .about__advantages {
    margin: 0 0 15px; } }

@media (max-width: 580px) {
  .about__block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .about__text {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    margin-right: 0;
    margin-bottom: 0; } }

@media (max-width: 480px) {
  .about__element {
    margin-left: 15px; }
  .about__title span {
    padding-left: 15px;
    width: 230px; }
  .about__relations-wrap {
    background: none; } }

.advantages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.advantages__item {
  -ms-flex-preferred-size: 24%;
      flex-basis: 24%; }

.advantages__img {
  min-height: 128px; }

p.advantages__title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin: 5px auto;
  max-width: 134px; }

p.advantages__text {
  font-size: 14px;
  line-height: 17px;
  margin: 5px auto;
  max-width: 134px; }

@media (max-width: 580px) {
  .advantages {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .advantages__item {
    -ms-flex-preferred-size: 49%;
        flex-basis: 49%;
    margin-bottom: 20px; }
  .advantages__img {
    text-align: center; } }

.footer {
  background: linear-gradient(184.33deg, #0050c8 24.71%, #00038d 72.57%); }

div.footer__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #fff; }

.footer__left {
  -ms-flex-preferred-size: 290px;
      flex-basis: 290px; }

.footer__right {
  -ms-flex-preferred-size: calc(100% - 290px);
      flex-basis: calc(100% - 290px); }

.footer__project {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px; }
  .footer__project a {
    color: #7db1ff; }

.footer__organizer {
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px; }

.footer__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .footer__list li {
    -ms-flex-preferred-size: 32.5%;
        flex-basis: 32.5%;
    margin: 5px 0;
    font-size: 12px;
    line-height: 15px; }
    .footer__list li:first-child a {
      color: #7db1ff; }
  .footer__list a {
    text-decoration: underline;
    color: #fff; }
    .footer__list a:hover {
      text-decoration: none; }

@media (max-width: 768px) {
  .footer__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  div.footer__wrap {
    padding-top: 20px;
    padding-bottom: 20px; }
  .footer__left {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    margin-bottom: 20px; }
  .footer__right {
    -ms-flex-preferred-size: auto;
        flex-basis: auto; } }

@media (max-width: 780px) {
  .footer__list li {
    -ms-flex-preferred-size: 49%;
        flex-basis: 49%; } }

.night {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg); }

.shooting_star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  background: linear-gradient(-45deg, #5f91ff, rgba(0, 0, 255, 0));
  border-radius: 999px;
  -webkit-filter: drop-shadow(0 0 6px #699bff);
          filter: drop-shadow(0 0 6px #699bff);
  -webkit-animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
          animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite; }
  .shooting_star::before, .shooting_star::after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
    -webkit-transform: translateX(50%) rotateZ(45deg);
            transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    -webkit-animation: shining 3000ms ease-in-out infinite;
            animation: shining 3000ms ease-in-out infinite; }
  .shooting_star::after {
    -webkit-transform: translateX(50%) rotateZ(-45deg);
            transform: translateX(50%) rotateZ(-45deg); }
  .shooting_star:nth-child(1) {
    top: calc(50% - 26px);
    left: calc(50% - 155px);
    -webkit-animation-delay: 816ms;
            animation-delay: 816ms; }
    .shooting_star:nth-child(1)::before, .shooting_star:nth-child(1)::after, .shooting_star:nth-child(1)::after {
      -webkit-animation-delay: 816ms;
              animation-delay: 816ms; }
  .shooting_star:nth-child(2) {
    top: calc(50% - 150px);
    left: calc(50% - 281px);
    -webkit-animation-delay: 3ms;
            animation-delay: 3ms; }
    .shooting_star:nth-child(2)::before, .shooting_star:nth-child(2)::after, .shooting_star:nth-child(2)::after {
      -webkit-animation-delay: 3ms;
              animation-delay: 3ms; }
  .shooting_star:nth-child(3) {
    top: calc(50% - 147px);
    left: calc(50% - 176px);
    -webkit-animation-delay: 4254ms;
            animation-delay: 4254ms; }
    .shooting_star:nth-child(3)::before, .shooting_star:nth-child(3)::after, .shooting_star:nth-child(3)::after {
      -webkit-animation-delay: 4254ms;
              animation-delay: 4254ms; }
  .shooting_star:nth-child(4) {
    top: calc(50% - -142px);
    left: calc(50% - 225px);
    -webkit-animation-delay: 9753ms;
            animation-delay: 9753ms; }
    .shooting_star:nth-child(4)::before, .shooting_star:nth-child(4)::after, .shooting_star:nth-child(4)::after {
      -webkit-animation-delay: 9753ms;
              animation-delay: 9753ms; }
  .shooting_star:nth-child(5) {
    top: calc(50% - -155px);
    left: calc(50% - 78px);
    -webkit-animation-delay: 6384ms;
            animation-delay: 6384ms; }
    .shooting_star:nth-child(5)::before, .shooting_star:nth-child(5)::after, .shooting_star:nth-child(5)::after {
      -webkit-animation-delay: 6384ms;
              animation-delay: 6384ms; }
  .shooting_star:nth-child(6) {
    top: calc(50% - 51px);
    left: calc(50% - 205px);
    -webkit-animation-delay: 2204ms;
            animation-delay: 2204ms; }
    .shooting_star:nth-child(6)::before, .shooting_star:nth-child(6)::after, .shooting_star:nth-child(6)::after {
      -webkit-animation-delay: 2204ms;
              animation-delay: 2204ms; }
  .shooting_star:nth-child(7) {
    top: calc(50% - -113px);
    left: calc(50% - 76px);
    -webkit-animation-delay: 9865ms;
            animation-delay: 9865ms; }
    .shooting_star:nth-child(7)::before, .shooting_star:nth-child(7)::after, .shooting_star:nth-child(7)::after {
      -webkit-animation-delay: 9865ms;
              animation-delay: 9865ms; }
  .shooting_star:nth-child(8) {
    top: calc(50% - -98px);
    left: calc(50% - 77px);
    -webkit-animation-delay: 5735ms;
            animation-delay: 5735ms; }
    .shooting_star:nth-child(8)::before, .shooting_star:nth-child(8)::after, .shooting_star:nth-child(8)::after {
      -webkit-animation-delay: 5735ms;
              animation-delay: 5735ms; }
  .shooting_star:nth-child(9) {
    top: calc(50% - -97px);
    left: calc(50% - 115px);
    -webkit-animation-delay: 4976ms;
            animation-delay: 4976ms; }
    .shooting_star:nth-child(9)::before, .shooting_star:nth-child(9)::after, .shooting_star:nth-child(9)::after {
      -webkit-animation-delay: 4976ms;
              animation-delay: 4976ms; }
  .shooting_star:nth-child(10) {
    top: calc(50% - 139px);
    left: calc(50% - 18px);
    -webkit-animation-delay: 4349ms;
            animation-delay: 4349ms; }
    .shooting_star:nth-child(10)::before, .shooting_star:nth-child(10)::after, .shooting_star:nth-child(10)::after {
      -webkit-animation-delay: 4349ms;
              animation-delay: 4349ms; }
  .shooting_star:nth-child(11) {
    top: calc(50% - -125px);
    left: calc(50% - 234px);
    -webkit-animation-delay: 5935ms;
            animation-delay: 5935ms; }
    .shooting_star:nth-child(11)::before, .shooting_star:nth-child(11)::after, .shooting_star:nth-child(11)::after {
      -webkit-animation-delay: 5935ms;
              animation-delay: 5935ms; }
  .shooting_star:nth-child(12) {
    top: calc(50% - -192px);
    left: calc(50% - 89px);
    -webkit-animation-delay: 6876ms;
            animation-delay: 6876ms; }
    .shooting_star:nth-child(12)::before, .shooting_star:nth-child(12)::after, .shooting_star:nth-child(12)::after {
      -webkit-animation-delay: 6876ms;
              animation-delay: 6876ms; }
  .shooting_star:nth-child(13) {
    top: calc(50% - -170px);
    left: calc(50% - 38px);
    -webkit-animation-delay: 7118ms;
            animation-delay: 7118ms; }
    .shooting_star:nth-child(13)::before, .shooting_star:nth-child(13)::after, .shooting_star:nth-child(13)::after {
      -webkit-animation-delay: 7118ms;
              animation-delay: 7118ms; }
  .shooting_star:nth-child(14) {
    top: calc(50% - -179px);
    left: calc(50% - 173px);
    -webkit-animation-delay: 222ms;
            animation-delay: 222ms; }
    .shooting_star:nth-child(14)::before, .shooting_star:nth-child(14)::after, .shooting_star:nth-child(14)::after {
      -webkit-animation-delay: 222ms;
              animation-delay: 222ms; }
  .shooting_star:nth-child(15) {
    top: calc(50% - 41px);
    left: calc(50% - 98px);
    -webkit-animation-delay: 5085ms;
            animation-delay: 5085ms; }
    .shooting_star:nth-child(15)::before, .shooting_star:nth-child(15)::after, .shooting_star:nth-child(15)::after {
      -webkit-animation-delay: 5085ms;
              animation-delay: 5085ms; }
  .shooting_star:nth-child(16) {
    top: calc(50% - 98px);
    left: calc(50% - 289px);
    -webkit-animation-delay: 3839ms;
            animation-delay: 3839ms; }
    .shooting_star:nth-child(16)::before, .shooting_star:nth-child(16)::after, .shooting_star:nth-child(16)::after {
      -webkit-animation-delay: 3839ms;
              animation-delay: 3839ms; }
  .shooting_star:nth-child(17) {
    top: calc(50% - -32px);
    left: calc(50% - 156px);
    -webkit-animation-delay: 1044ms;
            animation-delay: 1044ms; }
    .shooting_star:nth-child(17)::before, .shooting_star:nth-child(17)::after, .shooting_star:nth-child(17)::after {
      -webkit-animation-delay: 1044ms;
              animation-delay: 1044ms; }
  .shooting_star:nth-child(18) {
    top: calc(50% - -128px);
    left: calc(50% - 84px);
    -webkit-animation-delay: 6186ms;
            animation-delay: 6186ms; }
    .shooting_star:nth-child(18)::before, .shooting_star:nth-child(18)::after, .shooting_star:nth-child(18)::after {
      -webkit-animation-delay: 6186ms;
              animation-delay: 6186ms; }
  .shooting_star:nth-child(19) {
    top: calc(50% - -10px);
    left: calc(50% - 249px);
    -webkit-animation-delay: 3071ms;
            animation-delay: 3071ms; }
    .shooting_star:nth-child(19)::before, .shooting_star:nth-child(19)::after, .shooting_star:nth-child(19)::after {
      -webkit-animation-delay: 3071ms;
              animation-delay: 3071ms; }
  .shooting_star:nth-child(20) {
    top: calc(50% - 100px);
    left: calc(50% - 112px);
    -webkit-animation-delay: 5130ms;
            animation-delay: 5130ms; }
    .shooting_star:nth-child(20)::before, .shooting_star:nth-child(20)::after, .shooting_star:nth-child(20)::after {
      -webkit-animation-delay: 5130ms;
              animation-delay: 5130ms; }

@-webkit-keyframes tail {
  0% {
    width: 0; }
  30% {
    width: 100px; }
  100% {
    width: 0; } }

@keyframes tail {
  0% {
    width: 0; }
  30% {
    width: 100px; }
  100% {
    width: 0; } }

@-webkit-keyframes shining {
  0% {
    width: 0; }
  50% {
    width: 30px; }
  100% {
    width: 0; } }

@keyframes shining {
  0% {
    width: 0; }
  50% {
    width: 30px; }
  100% {
    width: 0; } }

@-webkit-keyframes shooting {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(300px);
            transform: translateX(300px); } }

@keyframes shooting {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(300px);
            transform: translateX(300px); } }

@-webkit-keyframes sky {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  100% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg); } }

@keyframes sky {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  100% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg); } }

@media (max-width: 880px) {
  .night {
    display: none; } }

.spoiler {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 22px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e0e0e0; }

.spoiler__title {
  padding: 10px 15px;
  position: relative;
  cursor: pointer; }
  .spoiler__title:hover {
    background-color: #F2F2F2;
    border-radius: 10px; }
  .spoiler__title::after {
    content: '';
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
    -webkit-transform: translateY(calc(-50% - 4px)) rotate(45deg);
            transform: translateY(calc(-50% - 4px)) rotate(45deg);
    position: absolute;
    right: 20px;
    top: 50%; }

.spoiler__hide {
  padding: 20px 15px 10px;
  display: none; }

.spoiler__list {
  margin-bottom: 15px;
  counter-reset: customCounter; }
  .spoiler__list li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 15px; }
    .spoiler__list li::before {
      content: counter(customCounter);
      counter-increment: customCounter;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 12px;
      line-height: 18px;
      width: 20px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border: 1px solid #ff8c14;
      text-align: center;
      border-radius: 50%; }

.spoiler__text {
  margin-bottom: 15px; }
  .spoiler__text a {
    color: #3194ae; }
    .spoiler__text a:hover {
      text-decoration: underline; }

.present {
  margin-bottom: 89px; }

.present__title {
  margin-bottom: 17px;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #c55431; }

.present__descr {
  margin-bottom: 55px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000; }

.present__descr span {
  font-weight: 700; }

.present__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.present__item:not(:last-child) {
  margin-bottom: 85px; }

.present__item-info {
  position: relative;
  padding-left: 47px;
  max-width: 456px; }

.present__item-number {
  position: absolute;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #fff;
  background: url("../../img/item-number-blue.svg") no-repeat; }
  .present__item-number--orange {
    background: url("../../img/item-number-orange.svg") no-repeat; }

.present__item-title {
  margin-bottom: 11px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #222; }

.present__item-descr {
  margin-bottom: 21px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #222; }

.present__item-descr span {
  font-weight: 700; }

.present__item-amount {
  display: inline-block;
  margin-bottom: 46px;
  border-radius: 5px;
  padding: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #d13100;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 225, 115, 0.8)), to(rgba(255, 210, 96, 0.8)));
  background: linear-gradient(180deg, rgba(255, 225, 115, 0.8) 0%, rgba(255, 210, 96, 0.8) 100%); }

.present__item-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #545454; }

.present__item-text--1 {
  max-width: 411px; }

.present__item-text--2 {
  max-width: 347px; }

.present__item-text--3 {
  max-width: 412px; }

.present__item-text--3 span {
  font-weight: 700;
  color: #545454; }

.present__item-text--3 a {
  font-weight: 700;
  color: #d13100; }

@media (max-width: 1100px) {
  .present__item-text {
    font-size: 14px; }
  .about__left-wrap {
    margin-right: 15px; } }

@media (max-width: 980px) {
  .present__item {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; } }

@media (max-width: 600px) {
  .present__item {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .present__item:not(:last-child) {
    margin-bottom: 35px; }
  .present__item-info {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .present__item-amount {
    margin-bottom: 28px; }
  .present__item-images {
    display: block;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin: 0 auto; }
  .present__item-text {
    margin-bottom: 15px; }
  .present__title {
    margin-bottom: 20px;
    font-size: 27px; }
  .present__descr {
    margin-bottom: 40px; }
  .present {
    margin-bottom: 63px; } }

.participation {
  position: relative;
  padding: 65px 0 54px;
  text-align: center;
  background: url("../../img/participation-bg.png") center center/cover no-repeat; }

.participation::before {
  content: "";
  position: absolute;
  left: 37px;
  bottom: 30px;
  width: 242px;
  height: 186px;
  background: url("../../img/participation-star.svg") no-repeat; }

.participation__title {
  margin-bottom: 38px;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #222; }

.participation__title span {
  color: #808080; }

.participation__link {
  display: inline-block;
  border-radius: 30px;
  padding: 14px 38px;
  -webkit-box-shadow: 0 10px 20px rgba(244, 139, 34, 0.4);
          box-shadow: 0 10px 20px rgba(244, 139, 34, 0.4);
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-27.78%, #ff531d), color-stop(98.63%, #ff7121));
  background: linear-gradient(180deg, #ff531d -27.78%, #ff7121 98.63%);
  -webkit-transition: background 0.5s;
  transition: background 0.5s; }

.participation__link:hover {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-27.78%, #e93800), color-stop(98.63%, #ed5500));
  background: linear-gradient(180deg, #e93800 -27.78%, #ed5500 98.63%);
  -webkit-transition: background 0.5s;
  transition: background 0.5s; }

@media (max-width: 1450px) {
  .participation::before {
    left: 17px;
    bottom: -30px;
    background-size: 60%; } }

@media (max-width: 1300px) {
  .participation::before {
    background: none; }
  .participation {
    padding: 35px 0 52px; }
  .participation__title {
    margin: 0 auto 17px;
    max-width: 500px;
    font-size: 22px; } }

@media (max-width: 600px) {
  .participation__title {
    font-size: 16px;
    line-height: 29px; }
  .participation {
    margin-bottom: 0;
    padding: 35px 0; } }

@media (max-width: 510px) {
  .participation__title {
    margin: 0 auto 12px; }
  .participation__link {
    font-size: 19px; } }

body {
  -webkit-box-shadow: none;
          box-shadow: none;
  font-family: "Montserrat", sans-serif;
  background: #fffae8; }

.wrap {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1152px; }

.main-wrapper {
  background: url("../../img/header-bg.png") center top no-repeat; }
  .main-wrapper .header {
    background: none; }

.full {
  position: relative;
  margin: 0 auto;
  max-width: 1350px;
  overflow: hidden; }
  .full > img {
    position: absolute; }

.full__icon-one {
  left: 0;
  top: 150px; }

.full__icon-two {
  left: 150px;
  top: 100px;
  z-index: 2; }

.full__icon-three {
  left: 220px;
  top: -10px;
  z-index: 2; }

.full__icon-four {
  right: 300px;
  top: -25px;
  z-index: 2; }

.full__icon-five {
  right: 150px;
  top: 100px; }

.full__icon-six {
  right: 0;
  top: 20px;
  z-index: 2; }

.bat {
  color: #131313; }
  .bat a {
    color: #0017b2; }

.profile_column h3,
.blok h3 {
  color: #131313; }

.key__archive_in_orders {
  color: #131313; }
  .key__archive_in_orders a {
    color: #0017b2; }

.v-uchsite,
.a-uchsite,
.a-alert-block {
  background: #fff; }

.v-h1-contact {
  color: #131313; }

.key_order {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: linear-gradient(357.81deg, #ffd260 5.58%, #ffe375 89.74%); }

.a-sort .a-sort-1 a {
  border: 1px solid #e0e0e0;
  background: none; }
  .a-sort .a-sort-1 a:hover {
    border: 1px solid #e0e0e0;
    color: #131313;
    background: none; }

.a-sort .a-sort-1 a.aa-filter-bg {
  color: #131313;
  background: #fff; }

.container .v-form-order {
  border: none; }
  .container .v-form-order .a-svod .a-inf-svod-ol {
    font-weight: 700;
    font-size: 16px; }

.a-svod-red {
  margin-top: 0;
  margin-right: 0; }

.order-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 0 10px;
  padding: 0 20px; }

.order-top__date {
  font-size: 12px;
  line-height: 16px;
  color: #b7b7b7; }

.mi_order__list input:first-child {
  padding-right: 100px; }

.tabs-vertical > ul.tabs {
  counter-reset: myCounter !important; }
  .tabs-vertical > ul.tabs li {
    position: relative !important; }
    .tabs-vertical > ul.tabs li a {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-box-align: center !important;
          -ms-flex-align: center !important;
              align-items: center !important;
      -webkit-box-pack: start !important;
          -ms-flex-pack: start !important;
              justify-content: flex-start !important;
      border: 1px solid #e0e0e0 !important;
      border-radius: 0 !important;
      padding-left: 35px !important;
      font-size: 14px !important;
      line-height: 16px !important;
      text-align: left !important;
      background: none !important; }
      .tabs-vertical > ul.tabs li a::after {
        counter-increment: myCounter !important;
        content: counter(myCounter) !important;
        position: absolute !important;
        left: 7px !important;
        top: 50% !important;
        z-index: 1 !important;
        -webkit-clip-path: none !important;
                clip-path: none !important;
        border-radius: 50% !important;
        width: 22px !important;
        height: 22px !important;
        font-weight: 700 !important;
        font-size: 12px !important;
        line-height: 22px !important;
        text-align: center !important;
        color: #fff !important;
        background: #0017b2 !important;
        -webkit-transform: translateY(-50%) !important;
                transform: translateY(-50%) !important; }
      .tabs-vertical > ul.tabs li a:hover {
        color: #131313;
        background: #faf7ef; }
    .tabs-vertical > ul.tabs li a.active {
      color: #131313 !important;
      background: none !important; }
      .tabs-vertical > ul.tabs li a.active::before {
        background: #0017b2 !important; }
      .tabs-vertical > ul.tabs li a.active::after {
        content: counter(myCounter) !important;
        left: 7px !important;
        bottom: auto !important;
        display: block !important;
        border: none !important;
        border-top-color: #0017b2 !important;
        width: 22px !important;
        height: 22px !important;
        color: #fff !important; }
    .tabs-vertical > ul.tabs li:nth-child(1)::before, .tabs-vertical > ul.tabs li:nth-child(2)::before, .tabs-vertical > ul.tabs li:nth-child(3)::before, .tabs-vertical > ul.tabs li:nth-child(4)::before {
      display: none !important; }

.tabs-vertical .view .a-for-tabl-zvk {
  background: none; }
  .tabs-vertical .view .a-for-tabl-zvk tr th {
    border-bottom: none;
    color: #bcb7a4; }
  .tabs-vertical .view .a-for-tabl-zvk tr:first-child {
    background: #faf7ef; }

.a-sort-m .a-escho {
  padding: 8px 10px;
  width: 160px;
  text-align: center; }

.a-sort-m .a-escho::before {
  display: none; }

@media (max-width: 1240px) and (min-width: 768px) {
  .a-sort-m .a-escho::before {
    left: 12px;
    top: calc(50% - 14px); } }

@media (max-width: 480px) {
  .a-sort-m .a-escho::before {
    top: calc(50% - 14px); } }

@media (max-width: 1240px) and (min-width: 768px) {
  .a-registration ul.a-admin.kr-main-menu li.a-l-l-pading {
    font-size: 14px; } }

@media (max-width: 991px) {
  .static {
    position: static !important; }
    .static .sidebar-menu {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%; }
  .pt-200 {
    padding-top: 200px; } }

@media (max-width: 1241px) {
  .key_orders_page .static {
    position: static !important; }
    .key_orders_page .static .sidebar-menu {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%; }
  .key_orders_page .pt-200 {
    padding-top: 200px; } }

@media (min-width: 320px) {
  .sidebar-menu {
    margin: 30px 0 20px;
    border-radius: 16px;
    padding: 15px 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background-color: #fff; }
    .sidebar-menu ul.a-admin li {
      clear: both;
      list-style-type: none;
      margin-right: 10px;
      margin-left: 10px;
      font-family: "Open Sans", serif;
      font-size: 14px; }
      .sidebar-menu ul.a-admin li a {
        color: #3194ae; }
        .sidebar-menu ul.a-admin li a span {
          display: inline-block;
          border-radius: 20px;
          padding: 3px 6px;
          width: 22px;
          text-align: center;
          color: #fff;
          background: #ffea00;
          background: -webkit-gradient(linear, left top, left bottom, from(#ffea00), to(#ffb500));
          background: linear-gradient(to bottom, #ffea00 0%, #ffb500 100%); }
        .sidebar-menu ul.a-admin li a:hover {
          text-decoration: none;
          color: #4598ac; }
      .sidebar-menu ul.a-admin li.dr-admin-circle {
        line-height: 16px; }
      .sidebar-menu ul.a-admin li.a-nou {
        opacity: 0.5; }
        .sidebar-menu ul.a-admin li.a-nou a {
          color: #555; }
        .sidebar-menu ul.a-admin li.a-nou:hover {
          opacity: 1; }
    .sidebar-menu .mi_header__name {
      margin: 0;
      padding: 0;
      font-weight: bold;
      color: #131313;
      background: none; }
    .sidebar-menu .kr-black-white-icons.kr-profile {
      background-position: -353px -41px; }
    .sidebar-menu .kr-black-white-icons.kr-star {
      background-position: -373px -41px; }
    .sidebar-menu .kr-black-white-icons.kr-archive {
      background-position: -373px -41px; }
    .sidebar-menu .kr-black-white-icons.kr-exit {
      background-position: -393px -41px; }
  .sidebar-menu ul.a-admin.kr-main-menu li {
    padding: 4px 0;
    -webkit-transition: ease-out 0.3s;
    transition: ease-out 0.3s; }
  .sidebar-menu ul.a-admin.kr-main-menu li > i {
    margin: 0 2px 0 0; } }

@media (max-width: 767px) {
  .container .v-form-order .tabs-vertical > ul.tabs li a.active::after {
    display: block !important; } }

@media (max-width: 560px) {
  .container .v-form-order {
    overflow-x: auto; }
  .v-form-order__wrap {
    min-width: 560px; } }

.kr-order-modal .kr-btn-cancel {
  background: #7dd35d; }

.a-r-troli {
  margin-top: 44px;
  font-weight: 400;
  font-size: 12px; }

.kr-pay-btn {
  border-radius: 100px;
  padding: 20px 30px; }

.v-ol-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #b7b7b7; }
  .v-ol-title b {
    font-weight: 400; }

.mi_order__inline_btn {
  margin-right: 10px; }

.dr-pupil-row .dr-red {
  top: 0; }

.dr-pupil-row .a-del-x {
  line-height: 12px; }

@media (max-width: 1240px) {
  .full__icon-four {
    top: -85px; }
  .full__icon-three {
    top: -85px; } }

@media (max-width: 1024px) {
  .full__icon-one {
    display: none; }
  .full__icon-two {
    display: none; }
  .full__icon-three {
    left: 0; }
  .full__icon-four {
    right: 100px;
    top: -35px; }
  .full__icon-five {
    display: none; }
  .full__icon-six {
    display: none; } }

@media (max-width: 880px) {
  .full__icon-three {
    display: none; }
  .full__icon-four {
    display: none; } }
