.advantages {
  display: flex;
  justify-content: space-between;
}

.advantages__item {
  flex-basis: 24%;
}

.advantages__img {
  min-height: 128px;
}

p.advantages__title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin: 5px auto;
  max-width: 134px;
}
p.advantages__text {
  font-size: 14px;
  line-height: 17px;
  margin: 5px auto;
  max-width: 134px;
}

@media (max-width: 580px) {
  .advantages {
    flex-wrap: wrap;
  }

  .advantages__item {
    flex-basis: 49%;
    margin-bottom: 20px;
  }

  .advantages__img {
    text-align: center;
  }
}