.present {
  margin-bottom: 89px;
}

.present__title {
  margin-bottom: 17px;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #c55431;
}

.present__descr {
  margin-bottom: 55px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000;
}

.present__descr span {
  font-weight: 700;
}

.present__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.present__item:not(:last-child) {
  margin-bottom: 85px;
}

.present__item-info {
  position: relative;
  padding-left: 47px;
  max-width: 456px;
}

.present__item-number {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #fff;
  background: url("#{$path-img}item-number-blue.svg") no-repeat;

  &--orange {
    background: url("#{$path-img}item-number-orange.svg") no-repeat;
  }
}

.present__item-title {
  margin-bottom: 11px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #222;
}

.present__item-descr {
  margin-bottom: 21px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #222;
}

.present__item-descr span {
  font-weight: 700;
}

.present__item-amount {
  display: inline-block;
  margin-bottom: 46px;
  border-radius: 5px;
  padding: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #d13100;
  background: linear-gradient(180deg, rgba(255, 225, 115, 0.8) 0%, rgba(255, 210, 96, 0.8) 100%);
}

.present__item-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #545454;
}

.present__item-text--1 {
  max-width: 411px;
}

.present__item-text--2 {
  max-width: 347px;
}

.present__item-text--3 {
  max-width: 412px;
}

.present__item-text--3 span {
  font-weight: 700;
  color: #545454;
}

.present__item-text--3 a {
  font-weight: 700;
  color: #d13100;
}

@media (max-width: 1100px) {
  .present__item-text {
    font-size: 14px;
  }

  .about__left-wrap {
    margin-right: 15px;
  }
}

@media (max-width: 980px) {
  .present__item {
    justify-content: flex-start;
  }
}

@media (max-width: 600px) {
  .present__item {
    flex-wrap: wrap;
  }

  .present__item:not(:last-child) {
    margin-bottom: 35px;
  }

  .present__item-info {
    order: 1;
  }

  .present__item-amount {
    margin-bottom: 28px;
  }

  .present__item-images {
    display: block;
    order: 2;
    margin: 0 auto;
  }

  .present__item-text {
    margin-bottom: 15px;
  }

  .present__title {
    margin-bottom: 20px;
    font-size: 27px;
  }

  .present__descr {
    margin-bottom: 40px;
  }

  .present {
    margin-bottom: 63px;
  }
}