$path-img: "../../img/";
$white: #fff;
$black: #131313;
$lightBlue: #bbc1ff;
$darkBlue: #0017b2;
$darkenBlue: #00048f;
$yellow: #fecb2f;
$darkOrange: #9d3c1e;

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap&subset=cyrillic");
@import "header";
@import "registration";
@import "information";
@import "form-order";
@import "about";
@import "advantages";
@import "footer";
@import "night";
@import "spoiler";
@import "present";
@import "participation";

body {
  box-shadow: none;
  font-family: "Montserrat", sans-serif;
  background: #fffae8;
}

.wrap {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1152px;
}

.main-wrapper {
  background: url("#{$path-img}header-bg.png") center top no-repeat;

  .header {
    background: none;
  }
}

.full {
  position: relative;
  margin: 0 auto;
  max-width: 1350px;
  overflow: hidden;

  > img {
    position: absolute;
  }
}

.full__icon-one {
  left: 0;
  top: 150px;
}

.full__icon-two {
  left: 150px;
  top: 100px;
  z-index: 2;
}

.full__icon-three {
  left: 220px;
  top: -10px;
  z-index: 2;
}

.full__icon-four {
  right: 300px;
  top: -25px;
  z-index: 2;
}

.full__icon-five {
  right: 150px;
  top: 100px;
}

.full__icon-six {
  right: 0;
  top: 20px;
  z-index: 2;
}

.bat {
  color: $black;

  a {
    color: $darkBlue;
  }
}

.profile_column,
.blok {
  h3 {
    color: $black;
  }
}

.key__archive_in_orders {
  color: $black;

  a {
    color: $darkBlue;
  }
}

.v-uchsite,
.a-uchsite,
.a-alert-block {
  background: $white;
}

.v-h1-contact {
  color: $black;
}

.key_order {
  border: none;
  box-shadow: none;
  background: linear-gradient(357.81deg, #ffd260 5.58%, #ffe375 89.74%);
}

.a-sort {
  .a-sort-1 {
    a {
      border: 1px solid #e0e0e0;
      background: none;

      &:hover {
        border: 1px solid #e0e0e0;
        color: $black;
        background: none;
      }
    }

    a.aa-filter-bg {
      color: $black;
      background: $white;
    }
  }
}

.container {
  .v-form-order {
    border: none;

    .a-svod {
      .a-inf-svod-ol {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
}

.a-svod-red {
  margin-top: 0;
  margin-right: 0;
}

.order-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;
  padding: 0 20px;
}

.order-top__date {
  font-size: 12px;
  line-height: 16px;
  color: #b7b7b7;
}

.mi_order__list {
  input {
    &:first-child {
      padding-right: 100px;
    }
  }
}

.tabs-vertical {
  >ul.tabs {
    counter-reset: myCounter !important;

    li {
      position: relative !important;

      a {
        display: flex !important;
        align-items: center !important;
        justify-content: flex-start !important;
        border: 1px solid #e0e0e0 !important;
        border-radius: 0 !important;
        padding-left: 35px !important;
        font-size: 14px !important;
        line-height: 16px !important;
        text-align: left !important;
        background: none !important;

        &::after {
          counter-increment: myCounter !important;
          content: counter(myCounter) !important;
          position: absolute !important;
          left: 7px !important;
          top: 50% !important;
          z-index: 1 !important;
          clip-path: none !important;
          border-radius: 50% !important;
          width: 22px !important;
          height: 22px !important;
          font-weight: 700 !important;
          font-size: 12px !important;
          line-height: 22px !important;
          text-align: center !important;
          color: $white !important;
          background: $darkBlue !important;
          transform: translateY(-50%) !important;
        }

        &:hover {
          color: $black;
          background: #faf7ef;
        }
      }

      a.active {
        color: $black !important;
        background: none !important;

        &::before {
          background: $darkBlue !important;
        }

        &::after {
          content: counter(myCounter) !important;
          left: 7px !important;
          bottom: auto !important;
          display: block !important;
          border: none !important;
          border-top-color: $darkBlue !important;
          width: 22px !important;
          height: 22px !important;
          color: $white !important;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        &::before {
          display: none !important;
        }
      }
    }
  }

  .view {
    .a-for-tabl-zvk {
      background: none;

      tr {
        th {
          border-bottom: none;
          color: #bcb7a4;
        }

        &:first-child {
          background: #faf7ef;
        }
      }
    }
  }
}

.a-sort-m .a-escho {
  padding: 8px 10px;
  width: 160px;
  text-align: center;
}

.a-sort-m .a-escho::before {
  display: none;
}

@media (max-width: 1240px) and (min-width: 768px) {
  .a-sort-m .a-escho::before {
    left: 12px;
    top: calc(50% - 14px);
  }
}

@media (max-width: 480px) {
  .a-sort-m .a-escho::before {
    top: calc(50% - 14px);
  }
}

@media (max-width: 1240px) and (min-width: 768px) {
  .a-registration ul.a-admin.kr-main-menu li.a-l-l-pading {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .static {
    position: static !important;

    .sidebar-menu {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  .pt-200 {
    padding-top: 200px;
  }
}

@media (max-width: 1241px) {
  .key_orders_page {
    .static {
      position: static !important;

      .sidebar-menu {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
    }

    .pt-200 {
      padding-top: 200px;
    }
  }
}

@media (min-width: 320px) {
  .sidebar-menu {
    margin: 30px 0 20px;
    border-radius: 16px;
    padding: 15px 10px;
    box-sizing: border-box;
    background-color: #fff;

    ul.a-admin li {
      clear: both;
      list-style-type: none;
      margin-right: 10px;
      margin-left: 10px;
      font-family: "Open Sans", serif;
      font-size: 14px;

      a {
        color: #3194ae;

        span {
          display: inline-block;
          border-radius: 20px;
          padding: 3px 6px;
          width: 22px;
          text-align: center;
          color: #fff;
          background: #ffea00;
          background: linear-gradient(to bottom, #ffea00 0%, #ffb500 100%);
        }

        &:hover {
          text-decoration: none;
          color: #4598ac;
        }
      }

      &.dr-admin-circle {
        line-height: 16px;
      }

      &.a-nou {
        opacity: 0.5;

        a {
          color: #555;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .mi_header__name {
      margin: 0;
      padding: 0;
      font-weight: bold;
      color: #131313;
      background: none;
    }

    .kr-black-white-icons {
      &.kr-profile {
        background-position: -353px -41px;
      }

      &.kr-star {
        background-position: -373px -41px;
      }

      &.kr-archive {
        background-position: -373px -41px;
      }

      &.kr-exit {
        background-position: -393px -41px;
      }
    }
  }

  .sidebar-menu ul.a-admin.kr-main-menu li {
    padding: 4px 0;
    transition: ease-out 0.3s;
  }

  .sidebar-menu ul.a-admin.kr-main-menu li > i {
    margin: 0 2px 0 0;
  }
}

@media (max-width: 767px) {
  .container .v-form-order .tabs-vertical > ul.tabs li a.active::after {
    display: block !important;
  }
}

@media (max-width: 560px) {
  .container {
    .v-form-order {
      overflow-x: auto;
    }
  }

  .v-form-order__wrap {
    min-width: 560px;
  }
}

.kr-order-modal {
  .kr-btn-cancel {
    background: #7dd35d;
  }
}

.a-r-troli {
  margin-top: 44px;
  font-weight: 400;
  font-size: 12px;
}

.kr-pay-btn {
  border-radius: 100px;
  padding: 20px 30px;
}

.v-ol-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #b7b7b7;

  b {
    font-weight: 400;
  }
}

.mi_order__inline_btn {
  margin-right: 10px;
}

.dr-pupil-row {
  .dr-red {
    top: 0;
  }

  .a-del-x {
    line-height: 12px;
  }
}

@media (max-width: 1240px) {
  .full__icon-four {
    top: -85px;
  }

  .full__icon-three {
    top: -85px;
  }
}

@media (max-width: 1024px) {
  .full__icon-one {
    display: none;
  }

  .full__icon-two {
    display: none;
  }

  .full__icon-three {
    left: 0;
  }

  .full__icon-four {
    right: 100px;
    top: -35px;
  }

  .full__icon-five {
    display: none;
  }

  .full__icon-six {
    display: none;
  }
}

@media (max-width: 880px) {
  .full__icon-three {
    display: none;
  }

  .full__icon-four {
    display: none;
  }
}