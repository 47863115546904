.form-order {
  position: relative;
  z-index: 1;
  margin-top: -20px;
  border: none;
  padding: 0;

  &::before {
    content: "";
    position: absolute;
    right: -140px;
    top: -74px;
    z-index: -1;
    width: 200px;
    height: 208px;
    background: url("#{$path-img}leaf-right.png") no-repeat;
  }

  &::after {
    content: "";
    position: absolute;
    left: -113px;
    top: -84px;
    z-index: -1;
    width: 179px;
    height: 208px;
    background: url("#{$path-img}leaf-left.png") no-repeat;
  }

  input:not([type="radio"]),
  input:not([type="checkbox"]),
  select {
    border-radius: 100px;
    height: 45px;
  }

  select {
    padding-right: 35px;
  }

  &--close {
    &::before,
    &::after {
      display: none;
    }

    .form-order__wrap {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
}

.form-order--no-leaf {
  margin-top: 0;

  &::after,
  &::before {
    display: none;
  }
}

.form-order__wrap {
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 -6px 20px rgba(0, 0, 0, 0.15);
  background: linear-gradient(357.81deg, #ffd260 5.58%, #ffe375 89.74%);
}

.form-order__title {
  color: #c55431;

  &::after,
  &::before {
    display: none;
  }
}

.form-order__login {
  input:not([type="radio"]),
  input:not([type="checkbox"]),
  select {
    background: $white;
  }
}

.form-order__login--inputs {
  > div {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.form-order__num_input {
  &:not([type="checkbox"]) {
    background: $white;
  }
}

.form-order__v-calc-prices {
  label {
    font-size: 18px;
    line-height: 22px;
    color: $darkOrange;
  }
}

.form-order__login_name_wr_select {
  &::before {
    content: "";
    right: 15px;
    width: 16px;
    height: 16px;
    background: url("#{$path-img}arrow-down.svg") no-repeat;
  }
}

.form-order__num_p {
  button {
    width: 30px;
    height: 30px;
    background: url("#{$path-img}plus.svg") center no-repeat;
  }
}

.key_order__num_m {
  button {
    width: 30px;
    height: 30px;
    background: url("#{$path-img}minus.svg") center no-repeat;
  }
}

.form-order__pay_summ {
  border: none;
  height: 45px;
  line-height: 45px;
  color: $black;
  background: $white;

  &::after,
  &::before {
    display: none;
  }
}

.form-order__btn_wr {
  margin-top: 0;
  border: none;
  padding: 0;
  background: none;

  button {
    position: relative;
    height: 45px;
    box-shadow: 0 10px 20px #f48b22;
    font-size: 24px;
    line-height: 29px;
    color: $white;
    background: linear-gradient(180deg, #ff531d -27.78%, #ff7121 98.63%);

    &::before {
      content: "";
      position: absolute;
      right: 50px;
      top: -40px;
      width: 35px;
      height: 35px;
      background: url("#{$path-img}orange-star.svg") center no-repeat;
      transform: rotate(25deg);
    }

    &::after {
      content: "";
      position: absolute;
      left: 50px;
      top: -40px;
      width: 35px;
      height: 35px;
      background: url("#{$path-img}orange-star.svg") center no-repeat;
    }

    &:hover {
      background: linear-gradient(180deg, darken(#ff531d, 10%) -27.78%, darken(#ff7121, 10%) 98.63%);
    }
  }
}

.form-order__msg {
  padding-top: 10px;
  background: none;

  p {
    font-size: 14px;
    line-height: 21px;
    color: #80660e;

    &:first-child {
      color: $black;
    }
  }

  a {
    text-decoration: underline;
    color: #80660e;

    &:hover {
      text-decoration: none;
    }
  }
}

@media (max-width: 1100px) {
  .form-order {
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .form-order__wrap {
    padding: 30px 15px;
  }

  .form-order__msg {
    margin: 15px 0 0;
    padding: 0;

    p {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

@media (max-width: 480px) {
  div.form-order__end {
    p {
      width: 100%;
    }
  }
}