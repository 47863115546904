.spoiler {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 22px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.spoiler__title {
  padding: 10px 15px;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: #F2F2F2;
    border-radius: 10px;
  }
  &::after {
    content: '';
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
    transform: translateY(calc(-50% - 4px)) rotate(45deg);
    position: absolute;
    right: 20px;
    top: 50%;
  }
}

.spoiler__hide {
  padding: 20px 15px 10px;
  display: none;
}

.spoiler__list {
  margin-bottom: 15px;
  counter-reset: customCounter;
  li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 15px;
    &::before {
      content: counter(customCounter);
      counter-increment: customCounter;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 12px;
      line-height: 18px;
      width: 20px;
      box-sizing: border-box;
      border: 1px solid #ff8c14;
      text-align: center;
      border-radius: 50%;
    }
  }
}

.spoiler__text {
  margin-bottom: 15px;
  a {
    color: #3194ae;
    &:hover {
      text-decoration: underline;
    }
  }
}