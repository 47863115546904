.footer {
  background: linear-gradient(184.33deg, #0050c8 24.71%, #00038d 72.57%);
}

div.footer__wrap {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  color: $white;
}

.footer__left {
  flex-basis: 290px;
}

.footer__right {
  flex-basis: calc(100% - 290px);
}

.footer__project {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  a {
    color: #7db1ff;
  }
}

.footer__organizer {
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;
}

.footer__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  li {
    flex-basis: 32.5%;
    margin: 5px 0;
    font-size: 12px;
    line-height: 15px;

    &:first-child {
      a {
        color: #7db1ff;
      }
    }
  }

  a {
    text-decoration: underline;
    color: $white;

    &:hover {
      text-decoration: none;
    }
  }
}

@media (max-width: 768px) {
  .footer__wrap {
    flex-direction: column;
  }

  div.footer__wrap {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footer__left {
    flex-basis: auto;
    margin-bottom: 20px;
  }

  .footer__right {
    flex-basis: auto;
  }
}

@media (max-width: 780px) {
  .footer__list {
    li {
      flex-basis: 49%;
    }
  }
}