.information {
  margin-top: 78px;
}

.information__wrap {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    left: -50px;
    bottom: 0;
    z-index: -1;
    width: 1250px;
    height: 400px;
    background: url("#{$path-img}circle-2.svg") center top no-repeat;
  }
}

.information__title {
  padding-top: 50px;
  padding-bottom: 15px;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: $white;
  background: url("#{$path-img}line.svg") center bottom no-repeat;
}

.information__img {
  display: block;
  margin: 10px auto;
  height: auto;
  max-width: 620px;
}

.information__cert {
  display: block;
  margin: 0 auto;
}

.information__name {
  position: relative;
  margin: 0 auto;
  max-width: 750px;

  &::after,
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 4%;
    width: 43px;
    height: 43px;
    background: url("#{$path-img}star-icon.svg") left top no-repeat;
    transform: translateY(-50%);
  }

  &::before {
    left: auto;
    right: 0;
  }
}

.information__certificate {
  margin: 20px 0;
  text-align: center;
}

.information__price,
.information__for-whom {
  position: absolute;
  z-index: 1;
  border-radius: 20px;
  padding: 15px;
  width: 172px;
  background-color: $darkBlue;

  &::after {
    content: "";
    position: absolute;
    left: 20px;
    bottom: 100%;
    z-index: -1;
    width: 112px;
    height: 270px;
    background: url("#{$path-img}gradient.svg") left top no-repeat;
  }
}

.information__price {
  left: 10px;
  bottom: 0;
  padding: 25px 20px;
  text-align: center;

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #fff;

    > span {
      display: block;
      margin-top: 8px;
      font-weight: 700;
      font-size: 55px;
      line-height: 64px;
      color: #fecb2f;

      span {
        margin-left: 10px;
        font-weight: 400;
      }
    }
  }
}

.information__for-whom {
  right: 10px;
  bottom: 0;

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: $white;

    span {
      color: $yellow;
    }
  }
}

@media (max-width: 1240px) {
  .information__wrap {
    &::after {
      left: 0;
      margin: 0 auto;
      width: 100%;
      max-width: 1240px;
      background-size: cover;
    }
  }
}

@media (max-width: 880px) {
  .information {
    margin-top: 0;
    padding-bottom: 30px;
    background: linear-gradient(to bottom, #00038d, #0042ff);
  }

  .information__title {
    padding-top: 25px;
  }

  .information__wrap {
    &::after {
      display: none;
    }
  }

  .information__for-whom,
  .information__price {
    position: static;

    &::after {
      display: none;
    }
  }

  .information__block {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .information__name {
    &::after,
    &::before {
      display: none;
    }
  }
}

@media (max-width: 400px) {
  .information__block {
    flex-direction: column;
  }

  .information__price {
    text-align: center;
  }

  .information__for-whom,
  .information__price {
    margin: 10px 0;
    width: auto;
  }
}