.about {

}

.about__wrap {
  display: flex;
}

.about__left-wrap {
  flex-basis: 364px;
  margin-right: 50px;
}

.about__left {
  margin-bottom: 97px;
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
  background: linear-gradient(358.01deg, #06f 0.85%, #00038d 98.32%);
}

.about__right {
  flex-basis: calc(100% - 384px);
}

.about__title {
  position: relative;
  display: inline-block;
  padding-right: 70px;

  &::after {
    content: "";
    position: absolute;
    right: 40px;
    top: 8px;
    width: 33px;
    height: 33px;
    background-color: $yellow;
    transform: rotate(45deg);
  }

  span {
    display: block;
    clip-path: polygon(100% 0, calc(100% - 25px) 50%, 100% 100%, 0 100%, 0 0);
    padding: 10px 0 10px 35px;
    width: 291px;
    box-sizing: border-box;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: $darkenBlue;
    background-color: $yellow;
  }
}

.about__element {
  margin: 10px 35px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 63px;

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $white;
  }

  span {
    display: block;
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  &:nth-of-type(1) {
    background: url("#{$path-img}about-left/about-1.svg") left 0 top 11px no-repeat;
  }

  &:nth-of-type(2) {
    background: url("#{$path-img}about-left/about-2.svg") left 0 top 11px no-repeat;
  }

  &:nth-of-type(3) {
    background: url("#{$path-img}about-left/about-3.svg") left 0 top 11px no-repeat;
  }

  &:nth-of-type(4) {
    background: url("#{$path-img}about-left/about-4.svg") left 0 top 11px no-repeat;
  }

  &:nth-of-type(5) {
    background: url("#{$path-img}about-left/about-5.svg") left 0 top 11px no-repeat;
  }

  &:nth-of-type(6) {
    background: url("#{$path-img}about-left/about-6.svg") left 0 top 11px no-repeat;
  }

  &:nth-of-type(7) {
    background: url("#{$path-img}about-left/about-7.svg") left 0 top 11px no-repeat;
  }

  &:nth-of-type(8) {
    background: url("#{$path-img}about-left/about-8.svg") left 0 top 11px no-repeat;
  }

  &:nth-of-type(9) {
    background: url("#{$path-img}about-left/about-9.svg") left 0 top 11px no-repeat;
  }

  &:nth-of-type(10) {
    background: url("#{$path-img}about-left/about-10.svg") left 0 top 11px no-repeat;
  }
}

.about__block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.about__subtitle {
  margin: 0 0 17px;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
}

.about__text {
  max-width: 358px;
}

.about__text p {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #000;
}

.about__text span {
  font-weight: 700;
}

.about__advantages {
  margin: 0 0 75px;
}

.about__specification {
  margin-bottom: 58px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #000;
}

.about__specification span {
  font-weight: 700;
}

.about__specification p:not(:last-child) {
  margin-bottom: 32px;
}

.about__relations {
  border-radius: 20px;
  background: linear-gradient(349.29deg, #ffd260 5.58%, #ffe375 89.74%);
}

.about__relations-wrap {
  padding: 30px 20px 102px;
  background: url("#{$path-img}relations-bg.svg") right 28px bottom 19px no-repeat;
}

.about__relations__title {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #242424;
}

.about__relations__descr {
  margin-bottom: 8px;
  max-width: 276px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #242424;
}

.about__relations__link {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #242424;
}

@media (max-width: 980px) {
  .about__wrap {
    flex-direction: column;
  }

  .about__left {
    flex-basis: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .about__right {
    flex-basis: auto;
  }

  .about__element {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .information__img {
    width: 100%;
    height: 100%;
  }

  .about__left-wrap {
    margin-right: 0;
  }

  .about__relations {
    margin-bottom: 20px;
  }

  .about__relations-wrap {
    padding: 30px 20px;
  }
}

@media (max-width: 600px) {
  .about__block img {
    max-width: 65%;
  }

  .about__advantages {
    margin: 0 0 15px;
  }
}

@media (max-width: 580px) {
  .about__block {
    flex-direction: column;
  }

  .about__text {
    flex-basis: auto;
    margin-right: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  .about__element {
    margin-left: 15px;
  }

  .about__title span {
    padding-left: 15px;
    width: 230px;
  }

  .about__relations-wrap {
    background: none;
  }
}