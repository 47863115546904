.header {
  color: $white;
  background: linear-gradient(to bottom, #06f 0%, #00038d 100%);
}

div.header__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header__logo {
  margin-right: 10px;
}

.header__link {
  display: block;
}

.header__dates {
  counter-reset: myCounter;
  display: flex;
  align-items: center;
}

.header__date {
  position: relative;
  padding: 0 40px;

  &::before {
    counter-increment: myCounter;
    content: counter(myCounter);
    position: absolute;
    left: 0;
    top: 50%;
    width: 37px;
    height: 37px;
    font-weight: 600;
    font-size: 16px;
    line-height: 37px;
    text-align: center;
    background: url("#{$path-img}star.svg") center no-repeat;
    transform: translateY(-50%);
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: $white;

    &:first-child {
      font-size: 12px;
      line-height: 15px;
      color: $lightBlue;
    }
  }

  &:last-child {
    p {
      &:last-child {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 980px) {
  div.header__wrap {
    flex-wrap: wrap;
  }

  .header__dates {
    flex-basis: 100%;
    justify-content: space-between;
    order: 3;
    margin-top: 20px;
  }
}

@media (max-width: 880px) {
  div.main-wrapper {
    background: none;

    .header {
      background: #00038d;
    }
  }
}

@media (max-width: 768px) {
  .header__dates {
    flex-wrap: wrap;
  }

  .header__date {
    flex-basis: 49%;
    margin: 10px 0;
  }
}