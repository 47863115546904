div.registration {
  margin-bottom: 0;
  ul.registration__menu {
    top: 44px !important;
    right: 0 !important;
    left: auto !important;
    .mi_header__name {
      color: $black;
    }
  }
}

.registration__link, .registration__cabinet {
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: $black;
  padding: 12px 20px;
  cursor: pointer;
  background-color: $yellow;
  border-radius: 100px;
  &:hover {
    background-color: darken($yellow, 10%);
  }
}