.participation {
  position: relative;
  padding: 65px 0 54px;
  text-align: center;
  background: url("#{$path-img}participation-bg.png") center center/cover no-repeat;
}

.participation::before {
  content: "";
  position: absolute;
  left: 37px;
  bottom: 30px;
  width: 242px;
  height: 186px;
  background: url("#{$path-img}participation-star.svg") no-repeat;
}

.participation__title {
  margin-bottom: 38px;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #222;
}

.participation__title span {
  color: #808080;
}

.participation__link {
  display: inline-block;
  border-radius: 30px;
  padding: 14px 38px;
  box-shadow: 0 10px 20px rgba(244, 139, 34, 0.4);
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
  background: linear-gradient(180deg, #ff531d -27.78%, #ff7121 98.63%);
  transition: background 0.5s;
}

.participation__link:hover {
  background: linear-gradient(180deg, #e93800 -27.78%, #ed5500 98.63%);
  transition: background 0.5s;
}

@media (max-width: 1450px) {
  .participation::before {
    left: 17px;
    bottom: -30px;
    background-size: 60%;
  }
}

@media (max-width: 1300px) {
  .participation::before {
    background: none;
  }

  .participation {
    padding: 35px 0 52px;
  }

  .participation__title {
    margin: 0 auto 17px;
    max-width: 500px;
    font-size: 22px;
  }
}

@media (max-width: 600px) {
  .participation__title {
    font-size: 16px;
    line-height: 29px;
  }

  .participation {
    margin-bottom: 0;
    padding: 35px 0;
  }
}

@media (max-width: 510px) {
  .participation__title {
    margin: 0 auto 12px;
  }

  .participation__link {
    font-size: 19px;
  }
}